<template>
  <div class="p-grid">
    <Toast />
    <FormSilogtran :service="service"  :loading="loading"   />
  </div>
</template>
<script>
import AcceptedOrder from '../service/AcceptedOrder';
import FormSilogtran from "@/components/forms/FormSilogtran";

export default {
  components: {
    FormSilogtran
  },
  data() {
    return {
      loading: true,
      service: {}
    }
  },
  AcceptedOrder: null,
  created() {
    this.AcceptedOrder = new AcceptedOrder();
  },
  mounted() {
    this.loading = true;
    this.AcceptedOrder.getOrder(this.$route.params.id).then(data => {

      this.service = data;
      this.service.departamentoOrigenCodigoDane = this.service.departamentoOrigenCodigoDane.id;
      this.service.departamentoDestinoCodigoDane = this.service.departamentoDestinoCodigoDane.id;

      if (this.service.pesoUnidadMedida != null)
        this.service.pesoUnidadMedida = this.service.pesoUnidadMedida.nombre;

      if (this.service.volumenUnidadMedida != null)
        this.service.volumenUnidadMedida = this.service.volumenUnidadMedida.nombre;

      for (const j in this.service.info_entregas) {

        for (const i in this.service.info_entregas[j].mercancia) {

          if (this.service.info_entregas[j].mercancia[i].dimensionesUnidadMedida != null)
            this.service.info_entregas[j].mercancia[i].dimensionesUnidadMedida = this.service.info_entregas[j].mercancia[i].dimensionesUnidadMedida.nombre;
          if (this.service.info_entregas[j].mercancia[i].pesoUnidadMedida != null)
            this.service.info_entregas[j].mercancia[i].pesoUnidadMedida = this.service.info_entregas[j].mercancia[i].pesoUnidadMedida.nombre;
          if (this.service.info_entregas[j].mercancia[i].volumenUnidadMedida != null)
            this.service.info_entregas[j].mercancia[i].volumenUnidadMedida = this.service.info_entregas[j].mercancia[i].volumenUnidadMedida.nombre;
          if (this.service.info_entregas[j].mercancia[i].valorArancelIvaMoneda != null)
            this.service.info_entregas[j].mercancia[i].valorArancelIvaMoneda = this.service.info_entregas[j].mercancia[i].valorArancelIvaMoneda.nombre;
          if (this.service.info_entregas[j].mercancia[i].valorMercanciaMoneda != null)
            this.service.info_entregas[j].mercancia[i].valorMercanciaMoneda = this.service.info_entregas[j].mercancia[i].valorMercanciaMoneda.nombre;

        }

        for (const i in this.service.info_entregas[j].contenedor) {

          if (this.service.info_entregas[j].contenedor[i].contenedorTipo != null)
            this.service.info_entregas[j].contenedor[i].contenedorTipo = this.service.info_entregas[j].contenedor[i].contenedorTipo.nombre;
          if (this.service.info_entregas[j].contenedor[i].contenedorTipoAbreviatura != null)
            this.service.info_entregas[j].contenedor[i].contenedorTipoAbreviatura = this.service.info_entregas[j].contenedor[i].contenedorTipoAbreviatura.codigo;
          if (this.service.info_entregas[j].contenedor[i].pesoUnidadMedida != null)
            this.service.info_entregas[j].contenedor[i].pesoUnidadMedida = this.service.info_entregas[j].contenedor[i].pesoUnidadMedida.nombre;
          if (this.service.info_entregas[j].contenedor[i].valorMercanciaMoneda != null)
            this.service.info_entregas[j].contenedor[i].valorMercanciaMoneda = this.service.info_entregas[j].contenedor[i].valorMercanciaMoneda.nombre;
          if (this.service.info_entregas[j].contenedor[i].valorArancelIvaMoneda != null)
            this.service.info_entregas[j].contenedor[i].valorArancelIvaMoneda = this.service.info_entregas[j].contenedor[i].valorArancelIvaMoneda.nombre;


        }

        for (const i in this.service.info_entregas[j].servicioAdicional) {

          if (this.service.info_entregas[j].servicioAdicional[i].servicioAdicional != null)
            this.service.info_entregas[j].servicioAdicional[i].servicioAdicional = this.service.info_entregas[j].servicioAdicional[i].servicioAdicional.nombre;
          if (this.service.info_entregas[j].servicioAdicional[i].valorTotalMoneda != null)
            this.service.info_entregas[j].servicioAdicional[i].valorTotalMoneda = this.service.info_entregas[j].servicioAdicional[i].valorTotalMoneda.nombre;

        }

      }

      this.loading = false;
    })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error al traer los datos',
            detail: 'Error al traer los datos',
            life: 5000
          });
          this.loading = false;
        });
  }
}
</script>

<style scoped lang="scss">
::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
