import axios from 'axios';
import { API_LOCAL_URL } from "@/config";
export default class AcceptedOrder {

    getOrders() {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'general',config).then(res => res.data);
    }

    getUsers() {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'user',config).then(res => res.data);
    }

    getGeneratedOrders(url) {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(url, config).then(res => res.data);
    }

    getAdicional() {
        const config = {
            headers: {
                "Authorization": "Bearer "+localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'adicionalesylog',config).then(res => res.data.data);
    }

    getSelects(slug) {
        const config = {
            headers: {
                "Authorization": "Bearer "+localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+slug,config).then(res => res.data.data);
    }

    async getSearchSelects(slug, name) {
      return new Promise((resolve)=> {
        const config = {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        };

        axios.get(API_LOCAL_URL +slug+'?q=' + name, config).then(res => { resolve(res.data.data) });
      });
    }

    async getSearchSelectTablaPor(name, CdCms) {
        return new Promise((resolve)=> {
            const config = {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            };

            axios.get(API_LOCAL_URL +'tablapor?q=' + name + '&cdcms=' + CdCms, config).then(res => { resolve(res.data.data) });
        });
    }

    getSelectTercSedes(IdTercero) {
        const config = {
            headers: {
                "Authorization": "Bearer "+localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'sede?IdTercero='+IdTercero,config).then(res => res.data.data);
    }

    getSelectTercAgencias(iDclie) {
        const config = {
            headers: {
                "Authorization": "Bearer "+localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'agencia?iDclie='+iDclie, config).then(res => res.data.data);
    }

    getOrder(id) {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'general/'+id,config).then(res => res.data.data);
    }

    getServiceOrder(id) {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'encabezado/'+id,config).then(res => res.data.data);
    }

    getLastEvent(placaRequest) {
        var placa = placaRequest || '';
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'lastevent?placa=' + placa, config).then(res => res.data.data);
    }

    getDocuments(id) {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'traceability/'+id,config).then(res => res.data);
    }

    getApiRestData(solicitud, pedido) {
        const config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        return axios.get(API_LOCAL_URL+'apirestdatadsv/'+solicitud+'/'+pedido,config).then(res => res.data);
    }

}